import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import { Controller } from "@hotwired/stimulus";
import { createPopper } from "@popperjs/core";

export default class extends Controller {
  static targets = ["body"];

  initialize() {
    const controller_name = "interpreting_sessions";
    const calendarElement = this.bodyTarget;

    const calendar = new Calendar(calendarElement, {
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
      events:
        "/" +
        controller_name +
        "/events/?q=" +
        this.queryString +
        "&user_id=" +
        this.userId,
      height: "auto",
      themeSystem: "standard",
      selectable: true,
      timeZone: false,
      nowIndicator: true,
      dayMaxEventRows: 3,
      aspectRatio: 1,
      slotMinTime: "5:00",
      slotMaxTime: "24:00",
      nextDayThreshold: "00:00:00",
      headerToolbar: {
        left: "dayGridMonth,timeGridWeek,timeGridDay",
        center: "title",
        right: "prev,next",
      },
      eventDisplay: "block",
      displayEventTime: true,
      displayEventEnd: true,
      eventTimeFormat: {
        hour: "numeric",
        minute: "2-digit",
        meridiem: "short",
      },
      eventClassNames: (argument) => {
        const eventProps = argument.event.extendedProps;
        const filledStatus = eventProps.filledStatus;
        const interpreterLocation = eventProps.interpreterLocation;
        const cancelled = eventProps.cancelled;
        const utilities = ["p-1"];

        if (cancelled) {
          utilities.push("opacity-50");
        }

        utilities.push(`${filledStatus}__${interpreterLocation}`);

        return utilities;
      },
      eventContent: (argument) => {
        const event = argument.event;
        let titleClasses = "text-black font-bold truncate";
        let timeClasses = "text-black text-xs";

        if (event.extendedProps.cancelled) {
          titleClasses += " line-through";
          timeClasses += " line-through";
        }

        const title = `<div class="${titleClasses}">${event.title}</div>`;
        const time = `<div class="${timeClasses}">${argument.timeText}</div>`;

        return { html: title + time };
      },
      eventClick: (info) => {
        let eventDateDay = new Date(info.event.start).getUTCDate();
        let eventDateMonth = new Date(info.event.start).getUTCMonth();
        let allVisibleDates = Array.from(
          document.querySelectorAll("[data-date]"),
        );

        let selectedDateElement = allVisibleDates.find((visibleDate) => {
          const isDateDaySame =
            new Date(visibleDate.dataset.date).getUTCDate() == eventDateDay;
          const isDateMonthSame =
            new Date(visibleDate.dataset.date).getUTCMonth() == eventDateMonth;

          return isDateDaySame && isDateMonthSame;
        });

        const id = info.event.extendedProps.interpretingSessionId;

        fetch("/" + controller_name + "/events/" + id)
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.text();
          })
          .then((data) => {
            const popoverContainer = document.createElement("div");
            popoverContainer.innerHTML = data;
            const popover = popoverContainer.firstChild;

            let body = document.querySelector("body");
            body.prepend(popover);

            createPopper(selectedDateElement, popover, {
              placement: "right",
              modifiers: [
                {
                  name: "flip",
                  options: {
                    fallbackPlacements: ["left", "top", "bottom"],
                  },
                },
              ],
            });
          });
      },
      select: ({ startStr, endStr, jsEvent }) => {
        const eventPopoverNode = document.querySelector("#event-popover");
        const isEventPopoverVisible =
          eventPopoverNode &&
          window.getComputedStyle(eventPopoverNode).display !== "none" &&
          eventPopoverNode.offsetWidth !== 0 &&
          eventPopoverNode.offsetHeight !== 0;

        if (isEventPopoverVisible) {
          jsEvent.preventDefault();
          document.querySelectorAll(".fc-highlight").forEach((element) => {
            element.style.display = "none";
          });
        } else {
          window.location = `/${controller_name}/new?start_at=${startStr}&end_at=${endStr}`;
        }
      },
    });

    calendar.render();
  }

  get queryString() {
    var queryString = new URLSearchParams(window.location.search).get("q");

    if (queryString) {
      return queryString;
    } else {
      return "";
    }
  }

  get userId() {
    var user_id = new URLSearchParams(window.location.search).get("user_id");

    return user_id ? user_id : "";
  }
}
