import { Controller } from "@hotwired/stimulus";
import { enter, leave } from "el-transition";
import { useClickOutside } from "stimulus-use";

export default class extends Controller {
  static targets = ["backdrop", "modal"];

  connect() {
    this.escapeKeyListener = this.escapeKeyListener.bind(this);
    this.leave = this.leave.bind(this);
    useClickOutside(this);

    this.boundEscapeKeyListener = this.escapeKeyListener.bind(this);
    window.addEventListener("keydown", this.boundEscapeKeyListener);
    this.enter();
  }

  disconnect() {
    window.removeEventListener("keydown", this.boundEscapeKeyListener);
  }

  escapeKeyListener(event) {
    if (event.key === "Escape") {
      this.dismiss();
    }
  }

  clickOutside(event) {
    event.preventDefault();
    this.dismiss();
  }

  dismiss() {
    this.leave();

    if (this.element.dataset.dismissUrl) {
      fetch(this.element.dataset.dismissUrl);
    }
  }

  acknowledge() {
    this.leave();
    fetch(this.element.dataset.acknowledgeUrl);
  }

  enter() {
    Promise.all([enter(this.backdropTarget), enter(this.modalTarget)]);
  }

  leave() {
    Promise.all([leave(this.backdropTarget), leave(this.modalTarget)]).then(
      () => {
        this.element.remove();
      },
    );
  }
}
